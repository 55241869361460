<template>
  <div class="error _under-construct">
    <div class="error__container _under-construct">
      <icon
          name="under_construction"
          width="400px"
          height="252px"
      />

      <div
        v-if="text.title"
        class="under-construct__title"
      >
        {{ text.title }}
      </div>
      <div
        v-if="text.subtitle"
        class="under-construct__subtitle"
      >
        {{ text.subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
import ROUTE_NAME from '@/router/routeName'

export default {
  props: {
    text: {
      type: Object,
      default: () => ({
        title: 'Страница недоступна на вашем тарифе',
        subtitle: 'Обратитесь к вашему аккаунт-менеджеру'
      })
    }
  },
  data () {
    return {
      ROUTE_NAME
    }
  }
}
</script>

<style lang="scss" src="./errors.scss" scoped></style>
